
body {
  background-color: #e2d4f0;
}

.ui.active, .ui.tabular.menu .active.item, .ui.fluid.small.relaxed.menu {
  background-color: #ede4f6;
}

.ui.tabular.menu {
  border-bottom: none;
}

body, .ui.header {
  font-family: press_start_kregular, monospace;
  line-height: 1.5em;
}

.underline {
  text-decoration: underline;
}

p {
  margin: 0 0 2em;
}

.ui.bulleted.list {
  .item {
    margin: 0 0 0.6em;
  }
}

.blink {
  -webkit-animation: 2s linear infinite condemned_blink_effect; /* for Safari 4.0 - 8.0 */
  animation: 2s linear infinite condemned_blink_effect;
}

/* for Safari 4.0 - 8.0 */
@-webkit-keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    flex: 0 0 auto;
  }
}